<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Promo Usage</h4> 
              </CCol>              
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
              </CCol>              
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

          <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
          </CRow> 

          <CRow>
             <CCol md="5">                   
                   <CInput
                      label="From Date"
                      type="date"
                      horizontal                      
                      v-model="fromDate" 
                      @input="startDateOnChange()" 
                    />            
              </CCol>
              <CCol md="5">                   
                   <CInput
                      label="To Date"
                      type="date"
                      horizontal
                      v-model="toDate" 
                      @input="toDateOnChange()" 
                    />            
              </CCol> 
              <CCol md="2"> 
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>              
              </CCol>     
          </CRow>

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>

                    <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>

        


        <div class="table-responsive">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="usedDate" sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter"> 

              <template v-slot:cell(usedDate)="{ item }"> 
                {{ item.usedDate }}
              </template>             

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import ReportService from '@/api/ReportService.js';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import moment from 'moment';


 export default {
    name: "promousage",
    components: {
      VueTypeaheadBootstrap,            
    },     
    data() { var date = new Date();
      return {
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10), 
        passpar:'',
        spinner:false,
        list:[],
        columns : [],
        data : [],
        items:[],             
        fields: [
          {
            key: 'userId',
            label : 'User Id',
            sortable: true
          },
          {
            key: 'mobileNumber',
            label : 'Mobile Number',
            sortable: true
          }, 
          {
            key: 'userName',
            label : 'User Name',
            sortable: true
          }, 
          {
            key: 'promoCode',
            label : 'Promo Code',
            sortable: true
          }, 
          {
            key: 'promotionType',
            label : 'Promo Type',
            sortable: true
          },                 
          {
            key: 'usedDate',
            label : 'Used Date',
            sortable: true
          },  
          {
            key: 'amount',
            label : 'Amount',
            sortable: true
          },
          {
            key: 'status',
            label : 'Status',
            sortable: true
          }            
        ],         
        currentPage: 1,
        perPage: 10,
        filter: null,
        formPasswd:false,
        filename:"promo-usage-report.xlsx",
        password:"",
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
      },

    created () { 
       this.spinner = true; 
       this.setDefaultPasswd();   
       this.getRequestParams();
       this.getPromoUsageReport(this.passpar);  
       this.setColumns();   
    },
    methods: { 

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },
       
      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },
      
      generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = [];  var data=[];
         let params = {}; var colkey =[];         

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          }           
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3];                          
                  var ckey = colkey[key4]; 
                  if(key3===ckey){  init[key4] = val; }              
                }
              }
              data.push(init); 
          } 

         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      getFilterData(){
         if(this.filter !== ''){             
            this.data  = this.list;             
         } else {            
            this.getRequestParams();
            this.getPromoUsageReport(this.passpar); 
         }
      },

      setColumns(){
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {           
           row['label'] = fields[key].label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      getRequestParams(){ 
          let params = {};
          params['fromDate'] = this.fromDate+"T00:00:00";
          params['toDate']   = this.toDate+"T23:59:59";   
          params['userId'] =  "all";       
          this.passpar = params;
      },

      startDateOnChange(){
        this.spinner = true;
        this.getRequestParams();
        this.getPromoUsageReport(this.passpar); 
      },

      toDateOnChange(){
        this.spinner = true;
        this.getRequestParams();
        this.getPromoUsageReport(this.passpar); 
      }, 
      
      setTimeByZone(usedDate){
          var tzoffset = (new Date()).getTimezoneOffset() * 60000;
          var ts   = new Date(usedDate).getTime();
          var result = (new Date(ts-tzoffset)).toISOString().substr(0, 19).replace("T"," ");
          return result;
      },

      getPromoUsageReport: function(params) {
        ReportService.getPromoUsageReport(params).then(resp => {  //console.log(resp);

           var rows = []; 
           for (var key in resp) { 
              var promoList  = resp[key].promoList;
              if (promoList.length !== 0) {
                for (var key2 in promoList) {   
                   let item = {};                         
                   item['userId'] = resp[key].userIdentify;
                   item['mobileNumber'] = resp[key].mobileNumber;
                   item['userName'] = !resp[key].userName ? "None" : resp[key].userName;                 
                   item['promoCode'] = promoList[key2].promoCode; 
                   item['usedDate']  = !promoList[key2].usedDate ? null : this.setTimeByZone(promoList[key2].usedDate);
                   item['amount']    = !promoList[key2].amount ? 0 : promoList[key2].amount;
                   item['status']    = !promoList[key2].currentStatus ? "None" : promoList[key2].currentStatus;                   
                   item['promotionType'] = promoList[key2].promotion.promotionType;                 
                   rows.push(item); 
                }
              } else {
                 item['userId'] = resp[key].userIdentify;
                 item['mobileNumber'] = resp[key].mobileNumber;
                 item['userName'] = !resp[key].userName ? "None" : resp[key].userName;   
                 item['promoCode'] = 'None';
                 item['usedDate']  = null;
                 item['amount']    = 0;
                 item['status']    =  'None';
                 item['promotionType']  = 'None'; 
                 var temp = JSON.stringify(item);                     
                 rows.push(temp);    
              }  
              
           }
           
           this.data  = rows; 
           this.items = rows;  this.spinner = false; 

        }, error => {
           this.loading = false;
        });
      },

    }
 }; 

</script>

<style>
    .scroll-y{ overflow-y:scroll;}    
</style>